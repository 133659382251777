var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "form-data",
            {
              ref: "materiallistReference",
              attrs: { references: _vm.formReferences, model: _vm.modelObj },
              on: {
                "update:references": function ($event) {
                  _vm.formReferences = $event
                },
              },
            },
            [
              _vm.modelObj.id && _vm.listOfDocuments.length > 0
                ? _c(
                    "template",
                    { slot: "materiallist_documents" },
                    [
                      _c("documents", {
                        attrs: {
                          documents: _vm.listOfDocuments,
                          deleteIds: _vm.deleteDocumentIds,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("template", { slot: "warehouse_product_batchno" }, [
                _c(
                  "div",
                  { staticClass: "custom_border" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "12" } },
                          [
                            _c("v-simple-table", {
                              attrs: { dense: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              attrs: { width: "50%" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.projectFeature.productNumber"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-left",
                                              attrs: { width: "50%" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "message.projectFeature.batchNr"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.warehouseProductBatches,
                      function (productBatch, index) {
                        return _c(
                          "v-row",
                          { key: index, staticClass: "mx-0" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.listOfProducts,
                                    "item-text": function (val) {
                                      return "" + (val.product_full_name || "")
                                    },
                                    "item-value": "product_id",
                                    "hide-details": "",
                                    outlined: "",
                                    label: "Material",
                                    dense: "",
                                    rules: [],
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getBatchByMaterials(
                                        index,
                                        +productBatch.product_id
                                      )
                                    },
                                  },
                                  model: {
                                    value: productBatch.product_id,
                                    callback: function ($$v) {
                                      _vm.$set(productBatch, "product_id", $$v)
                                    },
                                    expression: "productBatch.product_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pa-1", attrs: { sm: "6" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    loading: productBatch.batchLoading,
                                    disabled:
                                      productBatch.listOfBatches.length < 1,
                                    items: productBatch.listOfBatches,
                                    "item-text": "name",
                                    "item-value": "_id",
                                    "hide-details": "",
                                    outlined: "",
                                    label: "Batch no",
                                    dense: "",
                                    rules: [],
                                  },
                                  model: {
                                    value: productBatch.batch_no,
                                    callback: function ($$v) {
                                      _vm.$set(productBatch, "batch_no", $$v)
                                    },
                                    expression: "productBatch.batch_no",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-1",
                            staticStyle: { float: "right" },
                            attrs: { fab: "", "x-small": "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addNewProductBatchObj()
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-plus")])],
                          1
                        ),
                        _vm.warehouseProductBatches &&
                        _vm.warehouseProductBatches.length > 1
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mx-1",
                                staticStyle: { float: "right" },
                                attrs: {
                                  fab: "",
                                  "x-small": "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.warehouseProductBatches.pop()
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-minus")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }